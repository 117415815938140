<template>
    <div class="auth-main">
        <SLeftSideComponent/>
        <div class="auth-right">
            <div class="right-main d-flex flex-column h-100">
                <!-- auth header -->
                <SRightHeaderComponent/>

                <!-- dynamic content section -->
                <slot></slot>
            
            </div>
        </div> 
    </div>
</template>
<script>
export default {
    name:'Standapp',
    components: {
        SLeftSideComponent: () => import('@/components/admin/layouts/standapp_left/SLeftSideComponent.vue'),
        SRightHeaderComponent: () => import('@/components/admin/layouts/standapp_right_header/SRightHeaderComponent.vue')
    }
}
</script>
